/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
	display: none;
}

::selection {
	background: #4600a9;
}
::-moz-selection {
	background: #4600a9;
}

body {
	font-family: 'Open Sans', sans-serif;
	background-color: #0c001d;
}

.orbitron-no-glow {
	font-family: 'Orbitron';
}

/* nav start */

.active {
	transition: all 0.5s ease;
	background: #4600a9;
	box-shadow: 0px 0px 8px 2px #0000009a;
}

.Rectangle {
	width: 274px;
	height: 6px;
	background-color: #4600a9;
}

canvas {
	height: 67vh;
	width: 100%;
}

.brand-purple {
	background-color: #4600a9;
}

.connect-button {
	transition: 0.3s ease;
	line-height: 1.25;
	margin: 0 0 0 213.2px;
	padding: 22px 20px;
	border: solid 2px #934bff;
	background-image: linear-gradient(to bottom, #934bff, #6600f5 98%);
}

.connect-button:hover {
	box-shadow: 0 0 8px #fff;
}

.general-button {
	transition: 0.3s ease;
	line-height: 1.25;
	border: solid 2px #934bff;
	background-image: linear-gradient(to bottom, #934bff, #6600f5 98%);
}

.general-button:hover {
	box-shadow: 0 0 8px #fff;
}

.styled-text {
	font-family: Orbitron;
	font-size: 16px;
	font-weight: 900;

	color: #fff;
}

.text-off-black {
	color: #0c001d;
}

.id-bar {
	background-color: #1d0044;
}

.modal-bg {
	background-color: #0c001d;
}

.join-the-team-rect {
	width: 483px;
	height: 10px;
	background-color: #4600a9;
	border: solid 1px #4600a9;
}

.title-border {
	border-bottom: 5px solid #4600a9;
}

.beta-text {
	width: 54px;
	height: 19px;
	font-family: Orbitron;
	font-size: 15px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 1.31px;
	color: #4600a9;
}

.link-text {
	color: #934bff;
}
