@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: #111827;
	font-family: 'Open Sans', sans-serif;
}

::selection {
	background: #312e81;
}
::-moz-selection {
	background: #312e81;
}

.orbitron {
	font-family: 'Orbitron';
	text-shadow: 0 0 8px #fff;
}

.orbitron-glow-black {
	font-family: 'Orbitron';
	text-shadow: 0 0 8px #000;
}

.orbitron-no-glow {
	font-family: 'Orbitron';
}

.link-orbitron {
	font-size: large;
	font-weight: 600;
	font-family: 'Orbitron';
}

.link-orbitron:hover {
	text-shadow: 0 0 8px #fff;
}

.custom-button {
	background: -webkit-linear-gradient(#a21caf, #4c1d95);
}

/* hero start */
.bg-pic {
	background-image: url('assets/bg-mobile.jpg');
	background-repeat: no-repeat;
}

.arrow {
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 50% 100%, 0 88%);
	clip-path: polygon(0 0, 100% 0, 100% 88%, 50% 100%, 0 88%);
}
/* hero end */

/* banner start */
.newsletter-button {
	width: 160px;
	height: 50px;
	background: -webkit-linear-gradient(#a21caf, #4c1d95);
	position: relative;
	color: white;
	font-weight: bold;
	text-align: center;
	line-height: 40px;
	letter-spacing: 2px;
}

/* banner end */

/* scrollbar start */
html,
body {
	margin: 0;
	padding: 0;
}

body {
	overflow: overlay;
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	background: rgba(90, 90, 90, 0.4);
}

::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1);
}
/* scrollbar end */

/*  card start*/
@media only screen and (min-width: 1200px) {
	.height-100 {
		height: 360px;
	}
}

.card-mixin {
	--aug-border-all: 1px;
	--aug-border-bg: white;

	--aug-inlay-all: 6px;
	--aug-inlay-bg: linear-gradient(#312e81, black);
	--aug-inlay-opacity: 0.5;
}
/* nav start */

.active {
	transition: all 0.5s ease;
	background: #312e81;
	box-shadow: 0px 0px 8px 2px #0000009a;
}

.menu-mixin {
	--aug-border-all: 1px;
	--aug-border-bg: white;

	--aug-inlay-all: 6px;
	--aug-inlay-bg: linear-gradient(#312e81, black);
}

.cta-button {
	background: -webkit-linear-gradient(#a21caf, #4c1d95);
	background: -moz-linear-gradient(#a21caf, #4c1d95);
	background: -o-linear-gradient(#a21caf, #4c1d95);
	background: linear-gradient(#a21caf, #4c1d95);
	position: relative;
	color: white;
	font-weight: bold;
	text-align: center;
	letter-spacing: 2px;
	background-position: 0 -15;
}

.cta-button:hover {
	background-position: 0 0;
}

/* nav end */

/* description start */
.desc-mixin {
	--aug-b-extend1: 50%;
	--aug-t-extend1: 50%;
	--aug-l-extend1: 50%;
	--aug-r-extend1: 50%;
}

.desc-parallax {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('./assets/image1.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hover-text-glow:hover {
	text-shadow: 0 0 8px #fff;
	color: white;
}

/* description end */

/* mint start */

.stone-link {
	color: #a21caf;
}
.stone-link:hover {
	color: #951fa0;
}

.countdown-mixin {
	--aug-border-all: 1px;
	--aug-border-bg: white;

	--aug-inlay-all: 6px;
	--aug-inlay-bg: linear-gradient(#a21caf, #312e81);
	--aug-inlay-opacity: 0.5;
	--aug-bl-extend1: 20%;
	--aug-br-extend2: 22%;
	--aug-l-extend1: 50%;
	--aug-r-extend1: 50%;
	--aug-t-extend1: 80px;
}

.mint-parallax {
	background-image: linear-gradient(
			rgba(0, 0, 0, 0.664),
			rgba(0, 0, 0, 0.603)
		),
		url('./assets/image2.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.mint-mixin {
	--aug-b-extend1: 30%;

	--aug-t-extend1: 50%;
	--aug-l-extend1: 50%;
	--aug-r-extend1: 50%;
}

.controls-mixin {
	--aug-border-all: 1px;
	--aug-border-bg: white;
	--aug-inlay-all: 6px;
	--aug-inlay-bg: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	--aug-inlay-opacity: 0.7;
	--aug-tl-extend2: 32%;
	--aug-tr-extend1: 30%;
	--aug-b-extend1: 80px;
	--aug-l-extend1: 50%;
	--aug-r-extend1: 50%;
}

input::selection {
	background: #e89def;
}
input::-moz-selection {
	background: #e89def;
}

/* mint end */

/* features start */

.feat-parallax {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('./assets/image3.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

/* features end */

/* roadmap start */

.line-bg {
	background: -webkit-linear-gradient(#4c1d95, #a21caf);
}

/* roadmap end */

/* FAQ start */

.faq-parallax {
	background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
		url('./assets/image4.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
/* FAQ end */

/* discord box */

.discord-box-mixin {
	--aug-border-all: 1px;
	--aug-border-bg: white;

	--aug-inlay-all: 6px;
	--aug-inlay-bg: linear-gradient(#312e81, black);
	--aug-l-extend1: 30%;
	--aug-r-extend1: 30%;
}
/* discord box */

.arrow-down {
	background-color: #312e81;
	height: 100px;
	clip-path: polygon(100% 0, 0 0, 50% 100%);
}
